import React from "react";
import Layout from "../components/Layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import blankCanvass from "../assets/images/blank-canvas.svg";
const NotFoundPage = () => (
  <Layout>
    <Container>
      <Row>
        <Col sm={12} md={6}>
          <h1 style={{ textAlign: "center", margin: "50px 0px" }}>
            You just hit a blank page ... the sadness.
          </h1>
        </Col>
        <Col sm={12} md={6}>
          <img
            style={{ margin: "50px 0px", width: "100%", textAlign: "center" }}
            alt="Not Found"
            src={blankCanvass}
          />
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default NotFoundPage;
