import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import NavDropdown from "react-bootstrap/NavDropdown";
export default NavCategories => {
  const data = useStaticQuery(graphql`
    query CategoryNavigationQuery {
      allDatoCmsCategory {
        edges {
          node {
            id
            slug
            categoryName
          }
        }
      }
    }
  `);
  return (
    <NavDropdown title="Categories" id="nav-dropdown">
      {data.allDatoCmsCategory.edges.map(({ node: category }) => (
        <NavDropdown.Item as="span" key={category.id}>
          <Link key={category.id} to={`/${category.slug}`}>
            {category.categoryName}
          </Link>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};
